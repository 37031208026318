<template>
<!--  style="height:100vh;"-->
<div class="auth-wrapper">
  <v-container>
    <v-alert
        type="success"
    >Enregistrez-vous ici pour gagner en temps lors de vos reservation</v-alert>
    <v-stepper v-model="e2">
      <v-stepper-header>
        <v-stepper-step
            :complete="e2 > 1"
            step="1"
        >
          Informations personnel
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="e2 > 2"
            step="2"
        >
          Informations supplémentaire
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Resumé
        </v-stepper-step>
      </v-stepper-header>
      <v-form
          class="multi-col-validation"
      >
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row style="margin-top: 1%;">
              <v-col
                  cols="12"
                  md="6"
              >
                <v-select
                    v-model="customer.cust_sexe"
                    dense
                    :items="['M.', 'Mme', 'Mle']"
                    label="Civilite"
                    outlined
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_lastname"
                    dense
                    required
                    hide-details
                    label="Nom"
                    outlined
                    placeholder="Nom"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_firstname"
                    dense
                    hide-details
                    required
                    label="Prénom"
                    outlined
                    placeholder="Prénom"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_birthday"
                    dense
                    type="date"
                    hide-details
                    label="Date d'anniversaire"
                    outlined
                    placeholder="date de naissance"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_birth_place"
                    dense
                    hide-details
                    label="Lieu de Naissance"
                    outlined
                    placeholder="lieu de Naissance"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-select
                    v-model="customer.cust_type_id"
                    dense
                    :items="['Carte Nationale d\'Identite(CNI)', 'Permis de conduire','Passeport', 'Attestation d\'identité','Carte consulaire']"
                    label="Type de piece d'identite"
                    outlined
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_reference_id"
                    dense
                    hide-details
                    label="Reference de la piece"
                    outlined
                    placeholder="Reference"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_id_expiration_date"
                    type="date"
                    dense
                    hide-details
                    label="Date d'expiration de la piece"
                    outlined
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_contact"
                    dense
                    hide-details
                    label="Contact"
                    outlined
                    placeholder="numero de telephone"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_place_residence"
                    dense
                    hide-details
                    label="Lieu de residence"
                    outlined
                    placeholder="Domicile"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-btn
                    color="primary"
                    @click="e2 = 1"
                >
                  Precedent
                </v-btn>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-btn
                    style="margin-left:1rem;margin-right: 1rem;"
                    color="primary"
                    @click="e2 = 2"
                >
                  Continuer
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_mother_name"
                    dense
                    hide-details
                    label="Non de la mere"
                    outlined
                    placeholder="Non complet"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_father_name"
                    dense
                    hide-details
                    label="Non du pere"
                    outlined
                    placeholder="Nom complet"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_job"
                    dense
                    hide-details
                    label="Profession"
                    outlined
                    placeholder="metier"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_pseudonym"
                    dense
                    hide-details
                    label="Pseudonyme"
                    outlined
                    placeholder="nom de carresse"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-autocomplete
                    v-model="customer.cust_nationality"
                    dense
                    name="room"
                    :item-text="'name'"
                    :item-value="'id'"
                    :items='Nationality'
                    label="Nationalite"
                    deletable-chips
                    filled
                    outlined
                ></v-autocomplete>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-autocomplete
                    v-model="customer.cust_city"
                    dense
                    :items="CountryRegion"
                    name="apartment"
                    :item-text="'name'"
                    :item-value="'id'"
                    label="Ville"
                    deletable-chips
                    filled
                    outlined
                ></v-autocomplete>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-autocomplete
                    v-model="customer.cust_country"
                    dense
                    :item-text="item => item[4]"
                    :item-value="item => item[4]"
                    :items="countryWorld"
                    name="apartment"
                    label="Pays"
                    deletable-chips
                    filled
                    outlined
                ></v-autocomplete>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_date_of_entering_ivory_coast"
                    type="date"
                    dense
                    hide-details
                    label="Date d'entre en cote d'ivoire"
                    outlined
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    v-model="customer.cust_email"
                    type="email"
                    dense
                    label="Addresse Email"
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-btn
                    color="primary"
                    @click="e2 = 1"
                >
                  Precedent
                </v-btn>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-btn
                    style="margin-left:1rem;margin-right: 1rem;"
                    color="primary"
                    @click="e2 = 3"
                >
                  Continuer
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-row style="margin-top: 3%;">
              <v-col cols="12" md="12">
                <v-toolbar-title>Resume</v-toolbar-title>
              </v-col>
            </v-row>
            <v-row align-content-md="center">
              <v-col
                  cols="12"
                  md="6"
              >
                Details du Client:  <br>
                <p><span>Date Anniversaire</span> :<strong>{{ customer.cust_birthday }}</strong></p>
                <p><span>Lieu de Naissance</span> :<strong>{{ customer.cust_birth_place }}</strong></p>
                <p><span>Nom du pere</span> :<strong>{{ customer.cust_father_name }}</strong></p>
                <p><span>Nom de la mere</span> :<strong>{{ customer.cust_mother_name }}</strong></p>
                <p><span>Nom de caresse</span> :<strong>{{ customer.cust_pseudonym }}</strong></p>
                <p><span>Pays</span> :<strong>{{ customer.cust_country }}</strong></p>
                <p><span>Ville</span> :<strong>{{ customer.cust_city }}</strong></p>
                <p><span>Date d'expiration de la piece</span> :<strong>{{ customer.cust_id_expiration_date }}</strong></p>
                <p><span>Reference de la piece</span> :<strong>{{ customer.cust_reference_id }}</strong></p>

                <p><span>Date d'entre en cote d'ivoire</span> :<strong>{{ customer.cust_date_of_entering_ivory_coast }}</strong></p>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                Details du Client:  <br>
                <p><span>Civilite</span> :<strong>{{ customer.cust_sexe }}</strong></p>
                <p><span>Nom</span> :<strong>{{ customer.cust_lastname }}</strong></p>
                <p><span>Prenom</span> :<strong>{{ customer.cust_firstname }}</strong></p>
                <p><span>Nationalite</span> :<strong>{{ customer.cust_nationality }}</strong></p>
                <p><span>Contact</span> :<strong>{{ customer.cust_contact }}</strong></p>
                <p><span>Piece</span> :<strong>{{ customer.cust_type_id }}</strong></p>
                <p><span>Email</span> :<strong>{{ customer.cust_email }}</strong></p>
                <p><span>Profession</span> :<strong>{{ customer.cust_job }}</strong></p>
                <p><span>Domicile</span> :<strong>{{ customer.cust_place_residence }}</strong></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-btn
                    color="primary"
                    @click="e2 = 2"
                >
                  Precedent
                </v-btn>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-btn
                    style="margin-left:1rem;margin-right: 1rem;"
                    color="primary"
                    @click="newCustomer"
                >
                  Valider
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-form>
    </v-stepper>
  </v-container>
</div>
</template>

<script>
import Customer from '../customer/CustomerModel';
import countryWorld from '../../assets/countryWorld'
import CountryRegion from '../../assets/CountryRegion'
import Nationality from '../../assets/Nationality'
import {VRow,VCol,VBtn, VDataTable,VSpacer,VToolbar, VTextField, VToolbarTitle} from 'vuetify'

export default {
  name: 'UserRegister',
  data() {
    return {
      e2: 1,
      customer:Customer,
    }
  },
  components: {
    VTextField,
    VToolbarTitle,
    VRow,
    VCol,
    VBtn,
    VDataTable,
    VSpacer,
    VToolbar,
  },
  methods: {
    newCustomer() {
      if (this.customer.cust_id_expiration_date === '') {
        this.customer.cust_id_expiration_date = null
      }
      this.customer.autoRegist = true;
      this.$axios.post(this.$endpoint.CreateNewCustomer, this.customer).then(rp => {
        console.log(rp)
        this.e2 = 1
      })
    },
  },
  setup() {
    return {
      countryWorld,
      Nationality,
      CountryRegion,
      Customer,
    }
  }
}
</script>

<style scoped>

</style>
